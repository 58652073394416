<template>
  <div>
    <div class="banner-wrapper">
      <div
          class="banner_info"
          style="
        background: url(/images/10_02.jpg) center no-repeat;
        background-size: cover;
      "
      >
        <!-- <img src="images/10_03.png" /> -->
      </div>
    </div>
    <div class="infopage">
      <div class="container clearfix">
        <div class="left">
          <div class="box">
            <dl>
              <dt>站内搜索</dt>
              <dd>Site Search</dd>
            </dl>
          </div>
        </div>

        <div class="right">
          <div class="box">
            <div class="title flex">
              <div class="flex_item">
                <b>站内搜索</b>
              </div>
            </div>
            <div class="searchList">
              <div>
                <input
                  type="text"
                  placeholder="输入关键字"
                  class="input"
                  style="width: 80%"
                  v-model="title"
                />
                <button type="button" class="btn" @click="searchClick">
                  搜索
                </button>
              </div>
              <div class="news-text">
                <ul v-if="loading">
                  <li style="text-align: center">
                    <p>正在搜索中...</p>
                  </li>
                </ul>
                <ul v-if="searchs.length != 0 && !loading">
                  <li
                    v-for="(item, index) in searchs"
                    :key="index"
                  >
                    <div  @click="toDetail(item)">
                      <div class="news-date">
                        <b>{{ handleDay(item.publishTime) }}</b>
                        <p>{{ handleDate(item.publishTime) }}</p>
                      </div>
                      <h2>
                        <a>{{ item.title }}</a>
                      </h2>
                      <p class="news-content">
                        {{ getText(item.content) }}
                      </p>
                    </div>
                  </li>
                </ul>
                <ul v-else-if="searchs.length == 0 && !loading">
                  <li style="text-align: center">
                    <p>抱歉！找不到您要搜索条件的标题</p>
                  </li>
                </ul>
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageNum"
                  :page-sizes="[10, 20, 50]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "search",
  data() {
    return {
      title: "",
      pageNum: 1,
      pageSize: 10,
      total: 0,
      searchs: [],
      loading: false,
    };
  },
  methods: {
    async getSearch() {
      if(this.title.length == 0){
        this.$message({
          message: '请输入关键字',
          type: 'error'
        });
        return;
      }
      this.loading = true;
      let res = await this.$api.searchTitle({
        title: this.title,
        page: this.pageNum,
        size: this.pageSize,
      });
      if (res.data.code == 200) {
        this.total = res.data.data.totalSize;
        this.searchs = res.data.data.list;
        this.loading = false;
      }
    },
    getText(str){
      if (str == null) {
        return "暂无内容";
      }
      let res = str.replace(/[^\u4e00-\u9fa5]/gi, "").replace("微软雅黑","")
      if(res.length > 90){
        res = res.slice(0,90) + '...'
      }
      return res
    },
    searchClick() {
      this.pageNum = 1;
      this.pageSize = 10;
      this.getSearch();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getSearch();
    },
    handleDay(publishTime) {
      let date = new Date(publishTime);
      let day = date.getDate();
      return day;
    },
    handleDate(publishTime) {
      let date = new Date(publishTime);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      return year + "-" + month;
    },
    handleContent(content) {
      if (content == null) {
        return "暂无内容";
      }
      let start = content.indexOf("<p>");
      let end = content.indexOf("</p>");
      let sub = content.substring(start + 3, end);
      let filterStr = this.filterHtml(sub);
      if (filterStr.length >= 110) {
        filterStr = filterStr.substring(0, 100);
        return filterStr + "...";
      }
      return filterStr;
    },
    filterHtml(val) {
      let vals = val.replace(/&[^;]+;/g, "");
      return vals.replace(/<[^>]+>/g, "");
    },
    async toDetail(item) {
      console.log(item);
      let res =  await this.$api.getTopColumnId({id:item.columnId});
      console.log(res.data);
      this.$router.push(`/contents/${res.data.data}/${item.columnId}/${item.id}.html`)
      // this.$router.push({
      //   path: "/view",
      //   query: { articleId: id, name: null },
      // });
    },
  },
  async mounted() {
    this.title = this.$route.query.title;
    this.getSearch();
  },
};
</script>

<style scoped>
.news-date {
  width: 70px;
  height: 70px;
  border: 1px solid #bfd4e9;
  float: left;
  margin-right: 30px;
  font-family: arial;
  color: #6198e2;
  background: #fff;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}
.news-date b {
  height: 43px;
  line-height: 42px;
  font-size: 26px;
}
.news-date p {
  font-size: 14px;
  margin: 0 5px;
  height: 26px;
  line-height: 26px;
  color: #6198e2;
  background: #fff;
  border-top: 1px solid #dfe7f3;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}
.news-content {
  width: 100%;
  height: 45px;
}

.news-text {
  margin-bottom: 8px;
  margin-top: 25px;
  margin-left: 10px;
}
.news-text li {
  margin-top: 25px;
}
.news-text li h2 {
  overflow: hidden;
  font-size: 16px;
  color: #333;
  font-weight: 700;
  height: 22px;
  line-height: 22px;
  margin-bottom: 8px;
  margin-top: 0;
}
.news-text li:hover a {
  color: #275caa;
}
.news-text li:hover .news-date {
  border: 1px solid #275caa;
  background: #275caa;
  color: #fff;
}
.news-text li:hover .news-date p {
  color: #fff;
  background: #275caa;
}
ul{
  margin-bottom: 30px;
}
</style>
